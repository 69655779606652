// import
// {BackendPage, FrontendPage, HR, InterviewPoddubny, InterviewPolyakov, Time}
//  from '../src/page/Blog/Blog'
import { BackendPage } from './page/Blog/BackendPage/BackendPage'
import { FrontendPage } from './page/Blog/FrontendPage/FrontendPage'
import { HR } from './page/Blog/HR/HR'
import { InterviewPoddubny } from './page/Blog/InterviewPoddubny/InterviewPoddubny'
import { InterviewPolyakov } from './page/Blog/InterviewPolyakov/InterviewPolyakov'
import { Time } from './page/Blog/Time/Time'
import { SchoolAsync } from './page/School/School.async'
import { FAQAsync } from './page/FAQ/FAQ.async'
import { BlogAsync } from './page/Blog/Blog.async'
import { NotFoundAsync } from './page/NotFound/NotFound.async'
import { HomeAsync } from './page/Home/Home.async'

export const routes = [
  { path: '/', element: <HomeAsync /> },
  { path: '/school', element: <SchoolAsync /> },
  // { path: '/program', element: <Preprogram /> },
  { path: '/faq', element: <FAQAsync /> },
  { path: '/blog', element: <BlogAsync /> },
  { path: '/blog/time', element: <Time /> },
  { path: '/blog/backend', element: <BackendPage /> },
  { path: '/blog/frontend', element: <FrontendPage /> },
  { path: '/blog/interviewPoddubny', element: <InterviewPoddubny /> },
  { path: '/blog/human_resources_it', element: <HR /> },
  { path: '/blog/interviewPolyakov', element: <InterviewPolyakov /> },
  { path: '*', element: <NotFoundAsync /> },
]
