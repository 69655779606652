import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import s from './Phone.module.sass'

export const Phone = ({ className }) => {
  const { t } = useTranslation()
  return (
    <div className={clsx(s.Phone, className)}>
      <a href={t('FooterContacts.tel')}>
        {t('FooterContacts.tel')}
      </a>
    </div>
  )
}
