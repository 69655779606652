export const vacancyContent = (
  <>
    <p>
      Для тренинга мы взяли настоящую вакансию. Текст с сайта HeadHunter. Изменен только «косметически».
    </p>
    <p>
      <b>
        Компания ООО «ЛенСтройСнабРемонт» ищет Frontend-разработчика (React)
      </b>
      <br />
      до 90 000 руб. на руки
    </p>
    <p>
      Требуемый опыт работы: 1–3 года
      <br />
      Полная занятость, полный день
    </p>
    <p>
      Наша команда занимается разработкой и поддержкой CRM-системы, мобильного приложения (Android) для внутреннего использования.
    </p>
    <p>
      На данный момент мы ведём разработку обновлённой системы и ищем в команду фронтенд-разработчиков на React. Команда бэкенд-разработчиков уже подготовила API.
    </p>
    <p>
      <b>
        Что вам нужно будет делать:
      </b>
    </p>
    <p>
      - Разработка фронтенда для CRM-системы.
    </p>
    <p>
      <b>
        От вас мы ожидаем:
      </b>
    </p>
    <p>
      - Хорошее знание Javascript;
      <br />
      - Знание фреймворка react и его библиотек;
      <br />
      - Знание верстки html5/css3;
      <br />
      - Опыт работы с Git;
      <br />
      - Умение работать в команде.
    </p>
    <p>
      <b>
        Плюсом будет:
      </b>
    </p>
    <p>
      - Опыт работы с node.js;
      <br />
      - Умение писать тесты.
    </p>
    <p>
      Если ты командный игрок, любящий своё дело, ответственный и аккуратный, то мы будем рады видеть тебя в нашей команде.
    </p>
    <p>
      ЗП от 60000 до 90000 по результатам собеседования.
    </p>
    <p>
      Испытательный 1 месяц.
    </p>
    <p>
      Оформление официальное по ТК РФ. Выплаты 2 раза в месяц.
    </p>
    <p>
      Наш офис находится по адресу: Проспект Просвещения, д. 21.
    </p>
    <p>
      <b>
        Ключевые навыки:
      </b>
    </p>
    <p>
      JavaScript, Node.js, React.
    </p>
  </>
)

export const coverLetterContent = (
  <>
    <p>
      Чтобы тренинг был максимально приближен к реальности, мы попросили Женю прислать образец сопроводительного письма, которое он направил бы вместе с резюме.
    </p>
    <p>
      <b>
        Текст письма:
      </b>
    </p>
    <p>
      «Здравствуйте!
    </p>
    <p>
      Я хочу работать в ЛенСтройСнабРемонт. Я разделяю ценности вашей компании. Мне нравится, что вы не боитесь использовать новые технологии.
    </p>
    <p>
      Пара слов обо мне: пишу на React и чистом JS, люблю верстать и стараюсь придерживаться БЭМ. Знаком с NextJS, styled components и Sass. Я работал в IT-отделах нескольких компаний, где научился командной работе и налаживанию диалога между сотрудниками. Также у меня есть небольшой опыт на фрилансе. В резюме есть примеры моих работ.
    </p>
    <p>
      Возможно, вы нуждаетесь в начинающих специалистах?
    </p>
    <p>
      Буду рад возможности обсудить ваши проекты и подробнее рассказать о себе».
    </p>
  </>
)

export const briefContent = (
  <>
    <p>
      Примерно так выглядело резюме, которое Женя направил техническому директору в рамках тренинга.
    </p>
    <p>
      <b>
        Желаемая должность — Frontend-разработчик
      </b>
    </p>
    <p>
      <b>
        Опыт работы:
      </b>
    </p>
    <p>
      1. Сентябрь 2019 — настоящее время (11 месяцев)
    </p>
    <p>
      <b>
        Свои учебные проекты
      </b>
    </p>
    <p>
      Frontend-разработчик Spotify App — приложение Spotify, через которое можно слушать музыку. Написано на React c использованием Spotify API, Spotify Playback SDK.
      <br />
      <a href="https://github.com/Barnicle/SpotifyApp" style={{ color: 'rgba(182, 153, 222, 1)' }}>
        https://github.com/Barnicle/SpotifyApp
      </a>
    </p>
    <p>
      Страница со списком вакансий: реализован фильтр, адаптивная вёрстка, проект написан на React с использованием SASS.
      <br />
      <a href="https://github.com/Barnicle/job_listings" style={{ color: 'rgba(182, 153, 222, 1)' }}>
        https://github.com/Barnicle/job_listings
      </a>
    </p>
    <p>
      Web-приложение, которое отображает первые 5 билетов с тестового сервера Aviasales. Реализованы фильтры по остановкам, по цене и длительности перелёта. Работал с API. Приложение написано на React с использованием Redux и Sass.
      <br />
      <a href="https://github.com/Barnicle/av_front" style={{ color: 'rgba(182, 153, 222, 1)' }}>
        https://github.com/Barnicle/av_front
      </a>
    </p>
    <p>
      Where in the world? — каталог стран и базовой информации о них. Можно искать нужную страну в поисковой строке и фильтровать по регионам. Написано на основе rest-countries API с использованием styled-components.
      <br />
      <a href="https://github.com/Barnicle/rest_countries" style={{ color: 'rgba(182, 153, 222, 1)' }}>
        https://github.com/Barnicle/rest_countries
      </a>
    </p>
    <p>
      2. Май 2018 — настоящее время (2 года и 3 месяца)
    </p>
    <p>
      <b>
        Балтийские набережные, ОАО
      </b>
      <br />
      Санкт-Петербург
      <br />
      Инженер технической поддержки:
    </p>
    <p>
      — сообщаю о багах или необходимости изменения функционала под потребности пользователя;
      <br />
      — помогаю программисту понять пользователя.
    </p>
    <p>
      3. Май 2020 — Июнь 2020 (2 месяца)
    </p>
    <p>
      <b>
        Фриланс
      </b>
    </p>
    <p>
      Frontend-разработчик в job4j.ru/edu:
    </p>
    <p>
      — исправление багов;
      <br />
      — улучшение UX.
    </p>
    <p>
      4. Октябрь 2017 — Май 2018 (8 месяцев)
      <br />
      <b>
        Технолаб Лтд.
      </b>
    </p>
    <p>
      Технический специалист:
    </p>
    <p>
      — работа с актуализацией базы данных;
      <br />
      — чтение чертежей/документации, перенос данных в ГИС «Образование».
    </p>
    <p>
      <b>
        Образование
      </b>
      <br />
      Высшее: ЛЭТИ, 2017
      <br />
      Электронные сертификаты: Responsive Web Design, 2019
    </p>
  </>
)
