import { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import { routes } from '../routes'

const App = () => {
  return (
    <>
      <Suspense fallback={(
        <div className="lds-default">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
)}
      >
        {useRoutes(routes)}
      </Suspense>
    </>
  )
}

export default App
