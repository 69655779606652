import { v4 as uuidv4 } from 'uuid'

export const timing = [
  {
    id: uuidv4(),
    time: '00:01',
    text: 'Знакомство с Александром — интервьюером',
  },
  {
    id: uuidv4(),
    time: '02:35',
    text: 'Знакомство с Николаем — кандидатом',
  },
  {
    id: uuidv4(),
    time: '05:00',
    text: 'Обратная связь от Александра на самопрезентацию Николая',
  },
  {
    id: uuidv4(),
    time: '08:53',
    text: 'Обратная связь по тексту резюме',
  },
  {
    id: uuidv4(),
    time: '11:20',
    text: 'Как работодатели формулируют требования в вакансии (иногда неудачно)',
  },
  {
    id: uuidv4(),
    time: '15:21',
    text: 'Хороший пример формулировки в резюме опыта решения задач',
  },
  {
    id: uuidv4(),
    time: '17:41',
    text: 'Поговорим о языке Python. Почему выбрал его?',
  },
  {
    id: uuidv4(),
    time: '20:02',
    text: 'Почему Python начнёт терять популярность',
  },
  {
    id: uuidv4(),
    time: '21:23',
    text: 'Какие сильные и слабые стороны Python ты уже знаешь?',
  },
  {
    id: uuidv4(),
    time: '24:29',
    text: 'Проверка кода статическими анализаторами. Рекомендации по другим языкам',
  },
  {
    id: uuidv4(),
    time: '26:06',
    text: 'Любимый вопрос на американских собеседованиях',
  },
  {
    id: uuidv4(),
    time: '30:10',
    text: 'Код новичков выглядит плохо — это нормально',
  },
  {
    id: uuidv4(),
    time: '41:26',
    text: 'Смотрим код',
  },
  {
    id: uuidv4(),
    time: '42:32',
    text: 'Что такое некрасивый код',
  },
  {
    id: uuidv4(),
    time: '47:38',
    text: 'Если хотите развиваться, сделайте проект целиком — поймёте все боли',
  },
  {
    id: uuidv4(),
    time: '50:15',
    text: 'Вопросы о базах данных',
  },
  {
    id: uuidv4(),
    time: '54:37',
    text: 'Что делать, если не знаешь ответа на вопрос',
  },
  {
    id: uuidv4(),
    time: '58:32',
    text: 'Почему на собеседовании задают вопросы по теории',
  },
  {
    id: uuidv4(),
    time: '1:02:55',
    text: 'Как просить обратную связь на собеседованиях',
  },
  {
    id: uuidv4(),
    time: '1:06:18',
    text: 'Комментарий по сопроводительному письму',
  },
  {
    id: uuidv4(),
    time: '1:11:49',
    text: 'Обратная связь от участников за кадром',
  },
]
