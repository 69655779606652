import { v4 as uuidv4 } from 'uuid'

export const timing = [
  {
    id: uuidv4(),
    time: '00:00',
    text: 'Почему ты хочешь работать в нашей компании?',
  },
  {
    id: uuidv4(),
    time: '00:51',
    text: 'С чего ты начнёшь работу?',
  },
  {
    id: uuidv4(),
    time: '02:37',
    text: 'Как бы ты организовал взаимодействие с командой?',
  },
  {
    id: uuidv4(),
    time: '03:44',
    text: 'Вопросы по HTML',
  },
  {
    id: uuidv4(),
    time: '07:25',
    text: 'Вопросы по CSS',
  },
  {
    id: uuidv4(),
    time: '15:21',
    text: 'Хороший пример формулировки в резюме опыта решения задач',
  },
  {
    id: uuidv4(),
    time: '11:22',
    text: 'Вопросы по JavaScript',
  },
  {
    id: uuidv4(),
    time: '15:44',
    text: 'Как бы ты организовал структуру проекта для CRM, например, на React?',
  },
  {
    id: uuidv4(),
    time: '16:46',
    text: 'Что такое веб-компоненты?',
  },
  {
    id: uuidv4(),
    time: '21:48',
    text: 'Итоги от техдира по прохождению собеседования',
  },
  {
    id: uuidv4(),
    time: '22:40',
    text: 'Комментарии на сопроводительное письмо, которое направлялось с резюме',
  },
  {
    id: uuidv4(),
    time: '25:30',
    text: 'Что нужно выяснять у работодателя перед собеседованием',
  },
  {
    id: uuidv4(),
    time: '28:20',
    text: 'С чего начинал как разработчик? Что было самым сложным?',
  },
  {
    id: uuidv4(),
    time: '30:30',
    text: 'Как оформить GitHub, чтобы усилить резюме',
  },
  {
    id: uuidv4(),
    time: '32:20',
    text: 'Советы, как ускорить трудоустройство',
  },
  {
    id: uuidv4(),
    time: '35:29',
    text: 'Должен ли Junior знать, как организовать работу с бэкендом и дизайнерами',
  },
]
