import { v4 as uuidv4 } from 'uuid'

export const interviewContent = [
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_1'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_1'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_2'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_2'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_3'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_3'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_4'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_4'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_5'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_5', 'answer_6'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_6'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: [
      'answer_7',
    ],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_7'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: [
      'answer_8',
    ],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_8'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: [
      'answer_9',
      'answer_10',
      'answer_11',
    ],
    background: '#3c4154',
    position: 'right',
  },
]
