export const coverLetterContent = (
  <>
    <p>
      Мы попросили Николая прислать образец сопроводительного письма, которое
      он направил бы вместе с резюме.
    </p>
    <p>
      Текст письма:
    </p>
    <p>
      «Обо мне: мне 23 года, в этом году закончил Гидрометеорологический
      университет по специальности инженер-эколог.
    </p>
    <p>
      Разработкой на Python так или иначе занимаюсь около года. Постоянно
      использую его для работы с собственными проектами: начиная с
      лабораторных и дипломной работы, где язык служил для обработки больших
      объёмов данных в csv-файлах, продолжая созданием телеграм-ботов для
      обработки финансовых итогов дня и отслеживания транспорта по данным
      Яндекса, и заканчивая веб-разработкой.
    </p>
    <p>
      На данный момент у меня есть:
      <br />
      1) Понимание работы веб-приложений (протокол http, обработка запросов,
      паттерн MVC, шаблонизация, ORM);
      <br />
      2) Базовые знания Python;
      <br />
      3) Навыки HTML5/CSS;
      <br />
      4) Навыки работы с GIT (init, commit, add, checkout, merge, push,
      clone);
      <br />
      5) Базовый Bash;
      <br />
      6) Практически вся профильная информация черпается из англоязычных
      источников;
      <br />
      7) Исполнительность, любознательность, умение отстаивать свою точку
      зрения и анализировать и принимать чужую.
    </p>
    <p>
      Я ознакомился с вашим проектом «Экскурсии с мобильным гидом» и был очень
      вдохновлен. Он потрясающий. Я люблю путешествия и понимаю, насколько он
      актуален, учитывая проблемы, с которыми потребитель сталкивается,
      попадая в другую страну или город.
    </p>
    <p>
      Также он создает площадку для плодотворного сотрудничества с различными
      представителями сферы туризма, обеспечивая пользователю наибольший
      комфорт в путешествии».
    </p>
  </>
)

export const briefContent = (
  <>
    <p>
      Примерно так выглядело резюме, которое Коля направил техническому
      директору в рамках тренинга.
    </p>
    <p>
      <strong>
        Желаемая должность -
      </strong>
      <strong>
        {' '}
        Junior Python web-developer
      </strong>
    </p>
    <p>
      <strong>
        Опыт работы:
      </strong>
    </p>
    <p>
      1. Февраль 2020 — настоящее время
    </p>
    <p>
      <strong>
        self-education, разработка асинхронного сервера для приёма метрик
      </strong>
    </p>
    <p>
      Реализовал клиентскую и серверную стороны, взаимодействующие в рамках установленного протокола.
    </p>
    <p>
      <a href="https://github.com/NikoPushkin/client-and-server-apps-for-work-with-metrics" style={{ color: 'rgba(182, 153, 222, 1)' }}>
        https://github.com/NikoPushkin/client-and-server-apps-for-work-with-metrics
      </a>
    </p>
    <p>
      Данная пара позволяет отправлять и запрашивать метрики со стороны клиента и сохранять и возвращать необходимые метрики со стороны сервера.
    </p>
    <p>
      Наибольшую сложность в данном проекте составило понимание асинхронности и работа с asyncio.
    </p>
    <p>
      2. Декабрь 2019 — апрель 2020
    </p>
    <p>
      <strong>
        Web-разработчик &mdash;&nbsp;
      </strong>
      <strong>
        фриланс
      </strong>
    </p>
    <p>
      Небольшое издательство обратилось с просьбой создать приватный онлайн-каталог для демонстрации продукции клиентам-оптовикам.
    </p>
    <p>
      kickticj.pythonanywhere.com
    </p>
    <p>
      Реализовал:
    </p>
    <p>
      функционал CRUD;
      <br />
      пагинация;
      <br />
      категории (many to many);
      <br />
      поиск по заголовкам;
      <br />
      фронтенд (html, css, JS);
      <br />
      бэкенд (django, PostgreSQL).
    </p>
    <p>
      По итогу решена проблема отсутствия площадки для демонстрации продукции.
    </p>
    <p>
      <strong>
        Параллельный проект
      </strong>
      <br />
      Работая в магазине у друзей, разработал телеграм-бота, который полностью рассчитывал финансовые итоги дня на основе списка продаж, учитывая курсы валют на момент составления итога.
    </p>
    <p>
      Бот обрабатывал строго составленный список с необходимыми параметрами и возвращал результат в необходимом формате.
    </p>
    <p>
      По итогу: снижены время- и трудозатраты, исключена возможность совершения ошибки при расчете.
    </p>
    <br />
    <p>
      <strong>
        Знание языков
      </strong>
      <br />
      Русский — родной
      <br />
      Английский — B1 (средний)
    </p>
    <p>
      <strong>
        Навыки:
      </strong>
      <br />
      Python, Django Framework, CSS, Bash, Linux, HTML5.
    </p>
    <p>
      <strong>
        Дополнительная информация о себе
      </strong>
      <br />
      Меня зовут Николай, мне 23 года и я начинающий Python-разработчик.
    </p>
    <p>
      На данный момент у меня есть:
    </p>
    <p>
      1) Понимание работы веб-приложений (протокол http, обработка запросов, паттерн MVC, шаблонизация, ORM);
    </p>
    <p>
      2) Базовые знания Python. Опыт использования и знания основного функционала и стандартной библиотеки;
    </p>
    <p>
      3) Python постоянно использовался и используется для работы с собственными проектами: начиная с лабораторных и дипломной работы, где он служил для обработки больших объёмов данных в csv-файлах, продолжая созданием телеграм-ботов для обработки финансовых итогов дня и отслеживания транспорта по данным Яндекса и заканчивая веб-разработкой;
    </p>
    <p>
      4) Навыки HTML5/CSS;
    </p>
    <p>
      5) Навыки работы с GIT (init, commit, add, checkout, merge, push, clone);
    </p>
    <p>
      6) Базовый Bash;
    </p>
    <p>
      7) Практически вся профильная информация черпается из англоязычных источников;
    </p>
    <p>
      8) Исполнительность, любознательность, умение отстаивать свою точку зрения и анализировать и принимать чужую.
    </p>
    <p>
      Некоторые из моих проектов:
      <br />
      <a href="https://github.com/NikoPushkin" style={{ color: 'rgba(182, 153, 222, 1)' }}>
        https://github.com/NikoPushkin
      </a>
    </p>
    <p>
      Буду рад познакомиться, а в дальнейшем сотрудничать и развиваться!
    </p>
  </>
)
