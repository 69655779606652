import { v4 as uuidv4 } from 'uuid'

export const interviewContent = [
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_1'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_1', 'answer_2', 'answer_3', 'answer_4'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_2'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_5'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_3'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_6', 'answer_7', 'answer_8'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_4'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_9', 'answer_10', 'answer_11', 'answer_12'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_5'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_13', 'answer_14'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_6'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text: ['answer_15', 'answer_16', 'answer_17'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_7'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text:
      ['answer_18', 'answer_19', 'answer_20'],
    background: '#3c4154',
    position: 'right',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_1',
    text: ['question_8'],
    background: '#141721',
    position: 'left',
  },
  {
    id: uuidv4(),
    interviewer: 'interviewer_2',
    text:
      ['answer_21', 'answer_22'],
    background: '#3c4154',
    position: 'right',
  },
]
